

















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

import { validationStateMixin } from '@/util/validationState'
import PageTitle from '@/components/PageTitle.vue'
import LoadingButton from '@/components/LoadingButton.vue'

@Component({
  components: { PageTitle, LoadingButton },
  mixins: [validationMixin, validationStateMixin],
  validations: {
    newPassword: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(128),
    },
  },
  computed: {
    ...mapGetters('asyncStatus', [
      'isInProgress',
      'getError',
      'hasSucceeded',
      'getPasswordStrengthMsg',
    ]),
  },
})
export default class PasswordEditView extends Vue {
  newPassword = ''

  @Prop({ type: String, required: true })
  token!: string

  editPassword() {
    this.$v.$touch()
    if (this.$v.$invalid) {
      return
    }

    this.$store.dispatch('editPassword', { newPassword: this.newPassword, token: this.token })
  }
}
